import {PaginationType} from "@revolve-app/app/core/utils/pagination";

export function randomString(length: number): string {
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

export function paginationQueryWrapper(pagination: PaginationType, filter: any){
  let filterObject:any = {
    limit: pagination.limit,
    page: pagination.page,
    sortBy: pagination.order[0][0] + ':' + pagination.order[0][1]
  }
  for(let key in filter){
    if(filter[key] || typeof filter[key] === 'boolean' || filter[key] === 0)
      filterObject['filter.' + key] = filter[key];
  }
  return filterObject
}


export function onlyPaging(pagination: {offset: number, limit: number}){
  let filterObject:any = {
    limit: pagination.limit,
    page: pagination.limit,
  }
  return filterObject
}


export function generateBoundary(): string {
  let boundary = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~';
  const length = 32; // You can adjust the length of the boundary as needed

  for (let i = 0; i < length; i++) {
    boundary += characters.charAt(Math.floor(Math.random() * characters.length));
  }

  return boundary;
}

