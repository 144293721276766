import { Store } from '@ngrx/store';
import { inject } from '@angular/core';
import {Observable} from "rxjs";
import {DefaultConfig} from "ngx-easy-table";
import {isPlatformBrowser} from "@angular/common";

export interface  PaginationType {
  order: [[string, string]],
  sortBy: string,
  limit: number,
  offset: number,
  page?: number | null;
  count: number,
  totalPages: number,
  hasNextPage?: boolean
}

export const initialPaginationState: PaginationType = {
  order: [['id', 'desc']],
  sortBy:'id:desc',
  limit: 10,
  offset: 0,
  page: 1,
  count: -1,
  totalPages: 0
};

export class Pagination {
  public $data: any;
  public store: Store;
  public pagination: PaginationType = initialPaginationState;
  public tableConfiguration:any = {};
  public paginationConfig = {
    limit: 10,
    offset: 0,
    count: -1,
    page:0
  };
  public subscription?: Observable<any>;
  constructor(public pageUpdateAction: any,
              public startListRequest: any,
              public $paginationSelector: any,
              private platformId: Object,
              private getDataOnInit: boolean = true
  ) {
    this.store = inject(Store);
    if(isPlatformBrowser(this.platformId) && getDataOnInit) {
      this.store.dispatch(startListRequest());
    }
    this.subscription = this.store.select(this.$paginationSelector);
    this.tableConfiguration = { ...DefaultConfig };
    this.tableConfiguration.searchEnabled = false;
    this.tableConfiguration.orderEnabled = true;
    this.tableConfiguration.horizontalScroll = true;
    this.tableConfiguration.paginationEnabled = true;  // Enable pagination
    this.tableConfiguration.paginationRangeEnabled = false;  // Display page range
    this.tableConfiguration.rows = 10;
  }

  init() {
    this.subscription?.subscribe((data:any) => {
        this.pagination = {...data};
        if(this.paginationConfig.count === -1){
          this.paginationConfig = {
            page: Number(this.pagination.page),
            limit: this.pagination.limit,
            offset: Math.ceil((Number(this.pagination.page) -1) *  this.pagination.limit),
            count: this.pagination.count
          };
        }else{
          this.paginationConfig.offset = this.pagination.offset;
        }
    });
  }

  public paginationManager(newOffset: number = 0): void {
    if(newOffset > 0){
      this.pagination.offset = newOffset;
      this.pagination.page = Math.ceil(newOffset / this.pagination.limit) + 1;
      this.store.dispatch(this.pageUpdateAction({payload:{offset: newOffset, page: this.pagination.page, limit: this.pagination.limit} }));
      this.list();
    }

  }

  // Method to navigate to the next page
  public nextPage(): void {
    if(!this.pagination.page || this.pagination.page < this.pagination.totalPages){
      this.paginationManager(this.pagination.offset + this.pagination.limit);
    }
  }

  public prevPage(): void{
    this.paginationManager(this.pagination.offset - this.pagination.limit);
  }

  public goPage(pageNumber: number): void{
    this.paginationManager(pageNumber * this.pagination.limit);
  }

  public pageLimit(limit: number = 10): void{
    this.store.dispatch(this.pageUpdateAction({_limit: limit}))
  }


  public sortManager(key: string, value: string): void{
    this.pagination.order = [[key, value]];
    this.pagination.sortBy = key + ':' + value;
    this.store.dispatch(this.pageUpdateAction({payload: {order: this.pagination.order, sortBy: this.pagination.sortBy} }));
    this.list();
  }

  public list(): void{
    this.store.dispatch(this.startListRequest())
  }


  onEvent(event: any): void {
    if (event.event === 'onOrder') {
      this.sortManager(event.value.key, event.value.order);
    } else if(event.event !== 'onClick') {
      this.parseEvent(event);
    }
  }

  public parseEvent(obj: any): void {
    this.pagination.offset = obj.value.page * this.pagination.limit;
    this.paginationManager(this.pagination.offset)
  }

}
